

<template>
    <div class="text-center survey-preloader" v-if="loadingSurvey">
       <v-progress-linear
        indeterminate
        :size="50"
        :color="theme.colors.brandPrimColor"
        ></v-progress-linear>
    </div>
    <div id="surveyElement" class="container" v-else>
         
         <v-row justify="center">
          <v-col cols="12"  md="10">
                <div class="bottomNav">
                    <div class="container">
                        <div class="row justify-center">
                            <div class="col-md-10 col-12">
                                <div id="remainTimeDiv" v-if="surveyStatus == 'paused' || surveyStatus == 'new' " class="rem-time"><strong>{{$t('Asses.remainTime')}}:</strong> <button id="remainTime"></button> </div>
                                
                                <div class="custom-navigator d-none" id="navi">
                                    <select id="pageSelector" class="" @change="surveyCurrentPageNo">
                                        <option disabled>{{$t('Asses.choosePage')}}</option>
                                    </select>
                                    <i class="fas fa-angle-down"></i>
                                </div>
                                <div v-if="surveyStatus == 'paused' || surveyStatus == 'new'" class="working-survey">
                                    <a id="surveyComplete" @click="exitSurveyForNow"> {{$t('Asses.pause')}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center qloader-preloader" v-if="loadingQLoader">,
                    <v-progress-circular
                        indeterminate
                        :size="50"
                        :color="theme.colors.brandPrimColor"
                    ></v-progress-circular>
                </div>

                 

                <survey :survey='survey'/>
                
          </v-col>
         </v-row>
         <div class="text-center ma-2">
            <v-snackbar
            v-model="snackbar"
            >
            {{ snackbarText.notValidFileType }}
            <v-btn
                color="pink"
                text
                @click="snackbar = false"
            >
                {{$t('public.close')}}
            </v-btn>
            </v-snackbar>
        </div>
        
    </div>
</template>

<script>
    // VUEX
    import i18n from '@/plugins/i18n';
    import { mapGetters } from 'vuex';
    import Api from '../service/Api';
    import axios from 'axios';
    // import SurveyEditor from './components/SurveyEditor'
    import * as SurveyVue from 'survey-vue'
    import * as widgets from 'surveyjs-widgets';
    import ('jquery-ui/ui/widgets/datepicker.js');
    let Survey = SurveyVue.Survey
    widgets.jqueryuidatepicker(SurveyVue);


    // Survey.StylesManager.applyTheme("default");
    
    
    



    export default {
        components: {
            Survey,
        },
        data () {
            let json = {}
            var model = new SurveyVue.Model(json)
            return {
                survey: model,
                assessmentData: {},
                surveyResults: {},
                surveyStatus: 'new',
                wholeSurveyErrors: [],

                snackbar: false,
                snackbarText: {
                    notValidFileType : "الملفات المرفقة لابد ان تكون بالامتدادات التالية (.pdf | .png | .jpeg | .jpg | .doc | .xls | .xlsx | .docx)", 
                }

            }
        },
        beforeRouteLeave (to, from, next) {
            if (!from.meta.saved) {
                this.exitSurveyForNow();
                
            } else {
                next();
            }
        },
        mounted() {
            
            
            let vueScope = this;
            window.onbeforeunload = function(){
                vueScope.exitSurveyForNow();
            };

            const config = {
                headers: {
                    "Authorization": localStorage.getItem('FBidToken'),
                }
            }
            
            var put = this.$route.params.id
            this.$store.commit('SET_LOADING', { name: 'ui', value: true});
            Api().get(`/assessments/${this.$route.params.id}`,config)
            .then((res) => {
                if (res.data.status == 401 || res.data.status == 404 || res.data.status == 403 ) {
                    this.$store.commit('SET_LOADING', { name: 'ui', value: false});
                    this.$router.push({ name: 'notFound'}) ;   // ROUTE TO NotFound
                    
                } else {
                   this.assessmentData = res.data.data



                    
                    this.survey = new SurveyVue.Model(this.assessmentData);

                    


                    this.survey.onStarted.add(function(sender) {
                        Api().get(`/assessments/survey-start/`+put,config)
                        .then((res) => {
                            vueScope.$route.meta.saved = false;
                            let navi = document.getElementById('navi');
                            navi.classList.remove("d-none");
                            // navi.classList.add("d-flex");
                        })
                        .catch((error) => {
                            this.$store.commit('SET_LOADING', { name: 'ui', value: false});
                            this.$router.push({ name: 'notFound'}) ;   // ROUTE TO NotFound

                        })

                    });
                    
                
                    function startTimer(duration, display) {
                        
                        
                        if (!vueScope.survey.maxTimeToFinish) {
                            display.textContent = '∞';
                            //console.log($("#remainTime"))
                            document.getElementById("remainTimeDiv").style.display = "none"


                        } else if (duration > 0) {
                            // console.log(vueScope.survey.maxTimeToFinish)
                            var timer = duration, minutes, seconds;
                            minutes = parseInt(timer / 60, 10);
                            seconds = parseInt(timer % 60, 10);
        
                            minutes = minutes < 10 ? "0" + minutes : minutes;
                            seconds = seconds < 10 ? "0" + seconds : seconds;
        
                            display.textContent = minutes + ":" + seconds;
                            
                        } else {
                            vueScope.survey.doComplete();
                        }

                    }
                    
                    let display = document.getElementById('remainTime');
                    startTimer((this.survey.maxTimeToFinish - this.survey.timeSpent), display);
                   
                    this.survey.onTimerPanelInfoText.add((sender, options) => {      
                        startTimer((this.survey.maxTimeToFinish - this.survey.timeSpent), display);
                        options.text = ``;
                    });

                    // this.survey.onValidateQuestion.add(this.surveyValidateQuestion);


                    this.survey.onUploadFiles.add(function (survey, options) {
                        let formData = new FormData();
                        
                        options.files.forEach(function (file) {
                            if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "application/pdf" || file.type == "application/xls" || file.type == "application/xlsx" || file.type == "application/doc" || file.type == "application/docx" ) {
                                formData.append(file.name, file);
                            }
                        });

                        this.$store.commit('SET_LOADING', { name: 'qLoader', value: true});
                        const config = {
                            headers: {
                            "Authorization": localStorage.getItem('FBidToken'),
                            }
                        }
                        
                        Api().post('media/upload', formData, config)
                        .then((response) => {
                            options.callback("success", options.files.map((file, x) => {
                                if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "application/pdf" || file.type == "application/xls" || file.type == "application/xlsx" || file.type == "application/doc" || file.type == "application/docx" ) {
                                    // console.log(file.name);
                                    return {
                                        file: file,
                                        content: response.data.data[x][file.name].link
                                    };
                                } else {

                                }
                            }));
                            this.$store.commit('SET_LOADING', { name: 'qLoader', value: false});
                        })
                        .catch((error) => {
                            // console.log('isueeeeeeeeeeeeeeeee');
                            
                            this.snackbar = true;
                            this.$store.commit('SET_LOADING', { name: 'qLoader', value: false});

                        });
                    }.bind(this));

                    if(this.assessmentData.answers){ //check if edit assessment
                        this.survey.data =this.assessmentData.answers;
                        this.survey.currentPageNo  = this.assessmentData.pageNo;
                        this.$store.commit('SET_LOADING', { name: 'ui', value: false});

                    }

                    this.survey.onCurrentPageChanged.add(this.doOnCurrentPageChanged);
                    
                    this.survey.onComplete.add((result)  => {
                        this.$store.commit('SET_LOADING', { name: 'ui', value: true});
                        this.$route.meta.saved = true;
                        this.surveyResults.answers = result.data;
                        const config = {
                            headers: {
                                "Authorization": localStorage.getItem('FBidToken'),
                            }
                        }
                        
                        
                        if (this.surveyStatus == 'paused') {
                            this.surveyResults.status= 1;
                            this.surveyResults.pageNo = this.survey.currentPageNo
                            Api().put(`/assessments/${this.$route.params.id}`, this.surveyResults, config)
                            .then((res) => {
                                this.$store.commit('SET_LOADING', { name: 'ui', value: false});
                                this.$router.push({name: 'profile'});   // ROUTE TO PROFILE
                            })
                            .catch((error) => {
                                this.$store.commit('SET_LOADING', { name: 'ui', value: false});
                            })

                        } else {
                            this.surveyStatus = 'completed';
                            this.surveyResults.status= 2;
                            Api().put(`/assessments/${this.$route.params.id}`, this.surveyResults, config)
                            .then((res) => {
                                this.$store.commit('SET_LOADING', { name: 'ui', value: false});
                                this.$router.push({ name: 'assessmentReport', params: { id: this.$route.params.id } }) ;   // ROUTE TO Reports
                            })
                            .catch((error) => {
                                this.$store.commit('SET_LOADING', { name: 'ui', value: false});
                                this.$router.push({ name: 'notFound'}) ;   // ROUTE TO NotFound

                            })
                        }

                    });

                    if(this.assessmentData.status==2){
                        this.$store.commit('SET_LOADING', { name: 'ui', value: false});
                        this.$router.push({ name: 'assessmentReport', params: { id: this.$route.params.id } }) ;   // ROUTE TO Reports
                    }
                    this.setupPageSelector(this.survey);
                    this.doOnCurrentPageChanged(this.survey);
                }
                

            })
            .catch((error) => {
                this.$store.commit('SET_LOADING', { name: 'ui', value: false});
                vueScope.$router.push({ name: 'notFound'}) ;   // ROUTE TO NotFound

            })
            
        },
        methods: {
            surveyValidateQuestion(s, options) {
                
                if (s.isLastPage) {
                    
                } else {
                    for (let index = 0; index < s.currentPage.elements.length; index++) {
                        const element = s.currentPage.elements[index];
                        if (element.isRequired && !element.value) {
                            this.wholeSurveyErrors.push(element.name);
                            index == 4 ? this.survey.nextPage() : false;
                        }
                        return true
                    }
                    


                }
                
            },
            surveyCompleteLastPage(){
                this.survey.completeLastPage();
            },
            surveyNextPage () {
                this.survey.nextPage();
            },
            surveyPrevPage () {
                this.survey.prevPage();
            },
            surveyCurrentPageNo(e){
                this.survey.currentPageNo = e.target.value
            },
            doOnCurrentPageChanged(survey) {
                document
                    .getElementById('pageSelector')
                    .value = survey.currentPageNo;
                // document
                //     .getElementById('surveyPrev')
                //     .style
                //     .display = !survey.isFirstPage
                //         ? "inline"
                //         : "none";
                // document
                //     .getElementById('surveyNext')
                //     .style
                //     .display = !survey.isLastPage
                //         ? "inline"
                //         : "none";
                // document
                //     .getElementById('surveyComplete')
                //     .style
                //     .display = survey.isLastPage
                //         ? "inline"
                //         : "none";
                // document
                //     .getElementById('surveyProgress')
                //     .innerText = "Page " + (
                // survey.currentPageNo + 1) + " of " + survey.visiblePageCount + ".";
                if (document.getElementById('surveyPageNo')) 
                    document
                        .getElementById('surveyPageNo')
                        .value = survey.currentPageNo;
            },

            setupPageSelector(survey) {
                let vueScope = this;
                let localLang = this.$i18n.locale;
                
                var selector = document.getElementById('pageSelector');
                for (var i = 0; i < survey.visiblePages.length; i++) {
                    var option = document.createElement("option");
                    option.value = i;
                    option.text = vueScope.$i18n.messages[localLang].Asses.page + ' ' + (
                    i+1);
                    selector.add(option);
                }
            },
            
            exitSurveyForNow() {
                this.surveyStatus = 'paused'
                this.survey.doComplete();
                this.$route.meta.saved = true;

                // let options = {
                //         // html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
                //         loader: true, // set to true if you want the dailog to show a loader after click on "proceed"
                //         reverse: false, // switch the button positions (left to right, and vise versa)
                //         okText: 'Continue',
                //         cancelText: 'Close',
                //         animation: 'zoom', // Available: "zoom", "bounce", "fade"
                //         type: 'basic', // coming soon: 'soft', 'hard'
                //         verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
                //         verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
                //         clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
                //         backdropClose: false, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
                //         customClass: 'exit-dialog' // Custom class to be injected into the parent node for the current dialog instance
                //     };

                // this.$dialog.confirm(i18n.messages[this.locale].public.sureToLeave, options)
                // .then(function () {
                //     next();
                // })
                // .catch(function () {
                //     next(false);
                //     let exitBtn = document.getElementById("surveyComplete")
                //     exitBtn.style.backgroundColor = "green";
                //     exitBtn.style.color = "white";
                //     exitBtn.style.padding = "10px";
                //     exitBtn.style.fontSize = "15px";
                //     exitBtn.style.textDecoration = "none";
                //     exitBtn.style.borderRadius = "10px";
                    
                    
                // });
            }
            
        },
        computed: {
            ...mapGetters(["loadingSurvey", "loadingQLoader", "theme", "locale"]),
        }
    }

</script>

<style lang="scss" >

    @import '../assets/surveyVue.css';
    .v-progress-circular {
      margin: 1rem;
    }

    .survey-preloader {
      //position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      /* z-index: 1; */
      padding: 30%;
      background: white;
    }

    .qloader-preloader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
        padding: 30%;
        background: transparent;
    }



    // .rem-time {
    //     margin: 20px auto;
    //     position: absolute;
    //     z-index: 1;
    //     right: 5%;


    //     i {
    //         position: absolute;
    //         bottom: 8px;
    //         left: 33px;
    //         color: #f4f4f4;
    //         z-index: 0;
    //     }
        
    //     #remainTime {
    //         border-radius: 100px;
    //         width: 80px;
    //         height: 80px;
    //         background-color: #f4f4f4;
    //         color: red;
    //         font-weight: bold;
    //         font-size: 20px;
    //         border: 3px solid #ffb0b0;
    //     }

    //     #remainTime:hover {
    //         border-radius: 100px;
    //         width: 80px;
    //         height: 80px;
    //         background-color: red;
    //         color: white;
    //         font-weight: bold;

    //     }
    // }






    #surveyComplete:focus {
        background: green !important;
        padding: 20px;

    }


    .survey-completed {
        text-align: center;
    }
    


    













    .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating .sv_q_rating_item {
        word-spacing: -2px !important;
    }
    .sv_p_root > .sv_row:nth-child(even) {
        background: #fff !important;
    }
    .sv_p_root > .sv_row {
        // padding-bottom: 10px;
        // border: 1px dashed var(--brandHTextColor) !important;
        box-shadow: 0 0 10px 0 #ccc;
        border-radius: 0;
    }
    .sv_row {
        margin-bottom: 25px;
        border-radius: 10px;
        overflow: auto;
    }
    input {
        padding: 3px;
        margin-bottom:5px;
    }
    .sv_p_container {
        width: 100% !important;

    }
    .sv-boolean__switch {
        background: var(--brandPrimColor);
    }

    .sv_main.sv_main .sv-boolean  .sv-boolean__slider {
 
        background: white;
        margin-right:  calc(100% - 20px);
        margin-left:auto;

    }
     .sv_main.sv_main .sv-boolean.sv-boolean--checked.checked .sv-boolean__slider {
 
        background: white;
        margin-left:  calc(100% - 20px);
        margin-right:auto;

    }
    .sv_main{
        background: transparent
    }
    .sv_main .sv_custom_header {
    background-color: transparent;
    }
    .sv_main .sv_header {
        background: transparent !important;
        text-align: center;
        h3{
            text-align: center !important;
        }
    }
    .sv_main .sv_body {
        background-color: #f4f4f4;
        border-top: 0px;
        padding: 30px 35px !important;
        margin-bottom: 20px;
    }
    .sv_main .sv_container .sv_body .sv_nav {
    padding: 2em 0 0;
    min-height: 2em;
    display: inline-block;
    width: 100%;
}
.sv_main .sv_p_root > .sv_row {
    border-bottom: 0;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q{
    padding: 10px 0;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_other input, .sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_q_dropdown_control, .sv_main .sv_container .sv_body .sv_p_root .sv_q input:not([type="button"]):not([type="reset"]):not([type="submit"]):not([type="image"]):not([type="checkbox"]):not([type="radio"]), .sv_main .sv_container .sv_body .sv_p_root .sv_q select {
    height: calc(3em + 1px);
}
.sv_main .sv_container .sv_body .sv_p_root .sv_q .sv_select_wrapper:before {
    padding: 1.5em;
}
    .sv_main input[type="button"], .sv_main button{
        padding: 3px;
    border-radius: 4px;
    }
    .sv_main .sv_container .sv_header h3 {
    margin: 0;
}
    .sv_main input[type="button"]:hover, .sv_main button:hover {
        background-color:  var(--brandPrimColor);
        box-shadow:  0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
    }
    .sv_main .sv_container .sv_body .sv_p_root .sv_q {
        padding: 20px ;
    }

    .sv_main .sv_container .sv_body .sv_p_root .sv_q textarea {
        padding: 10px;
    }
    .sv_progress { 
            
               
            span{
                    direction: rtl;
                    width: 100%;
                    display: block;
                    padding-top: 15px;
            }

            
        
        }


        .sv_main .sv_container .sv_body .sv_p_root .sv_q_rating {
    line-height: 2em;
    text-align: center;
    font-size: 1.2em;
}

.sv_main .sv_q_rating_item.active .sv_q_rating_item_text {
    background-color:   var(--brandPrimColor);
    border-color:   var(--brandPrimColor);
    color: white;
}


    /* RTL CUSTOM */
    .v-application.v-application--is-rtl {
        .sv_select_wrapper select{
                padding-right: 1em !important;
        }
        .sv_select_wrapper:before{
               right: auto !important;
               left: 0;
        }

        .sv_qstn {
            float: right;

            clear: both;
        }

        .sv_main .sv_container .sv_body .sv_p_root table td, .sv_main .sv_container .sv_body .sv_p_root table th {
            text-align: right;
        }
        .sv_start_btn, .sv_next_btn, .sv_complete_btn {float: left !important;}
        .sv_prev_btn {float: right !important;}
        .sv_progress { 
            
                text-align: left;
                direction: ltr;
            span{
                    direction: ltr;
                    width: 100%;
                    display: block;
                    padding-top: 15px;
            }

            .sv_progress_bar {

                float: right;
                margin: 0 !important;
            }
        
        }

    }

    .v-application.v-application--is-ltr {
        .sv_start_btn, .sv_next_btn, .sv_complete_btn {float: right !important;}
    }
    

    .sv_progress_bar{
            background-color: #bdbdbd;
    }


.bottomNav{
    position: fixed;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    left: 0;
    right: 0;
    background: #fff;
    
    box-shadow: 0 0 5px 0 #ababab;
    .rem-time{
        display: inline-block;
        button{
            border: 2px solid #ff5252;
            border-radius: 20px;
            padding: 3px 20px;
            margin: 0 12px;
            color: #ff5252;
            font-weight: 800;
        }
        
    }
    .working-survey{
       // display: inline-block;
        float: left;
        margin: 4px 20px 0;
        font-weight: bold;
        a{
            // font-size: .8rem;
            color:rgba(0, 0, 0, 0.87);
            text-decoration: underline;
            &:hover{
                color: var(--brandPrimColor);
            }
        }
        @media (max-width: 786px){
            float: none;
            margin: 15px 20px 0;
            text-align: center;
        }
    }
    .custom-navigator {
       
        z-index: 5;
        text-align: center;
        -webkit-transition: all ease-in-out 2s;
        transition: all ease-in-out 2s;
         float: left;
        position: relative;


        select {
            border: 2px solid var(--brandPrimColor);
            border-radius: 20px;
            padding: 3px 15px 3px 30px;
            background: #fff;
            cursor: pointer;
            text-align: center;
            margin: auto;
            -webkit-transition: all ease-in-out 2s;
            transition: all ease-in-out 2s;
            font-weight: bold;
            color: var(--brandPrimColor);


            &:focus {
                outline: none;
                box-shadow: none;
            }

            

        }

        i {
            position: absolute;
            top: 10px;
            left: 10px;
        }
    }
}


.input-container input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}
  
.sv_q_file{
    .sv_q_file_remove_button{
        display: none;
    }
    .sv_q_file_remove{
        display: none;
        position: absolute;
        top: 10%;
        bottom: 0;
        right: 10%;
        left: 0;
        height: 80%;
        width: 80%;
        padding: 20% 5px 0;
        background: rgba(0, 0, 0, 0.82);
        color: #fff !important;
        border-radius: 20px;
    }
    
    .sv_q_file_preview{
        position: relative;
        width: 31%;
        height: 120px;
        overflow: hidden;
        text-align: center;
        margin: 1%;
        border: 1px solid #f4f4f4;
        padding: 15px;
        img{
            width: 100%;
            // height: 200px;
        }
        a{
           height: 40px;
            overflow: hidden;
            display: block;
        }

        &:hover {
            .sv_q_file_remove {
                display: block;
            }
        }
    }

    
}

    

    


</style>


